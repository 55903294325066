const validTours = {
  'contact-tour': true,
  contacts: true,
  'contacts-import': true,
  'create-contact': true,
  'create-contact-from-segmentation': true,
  'edit-theme-settings': true,
  'create-and-explore-meetings': true,
  'create-and-explore-meetings-without-connect-calendar': true,
  'create-first-marketing-email': true,
  'contacts-and-contact-record-explore-1': true,
  'contacts-and-contact-record-explore-2': true,
  'create-deal-1': true,
  'create-deal-2': true,
  'create-email-1': true,
  'create-email-2-with-send': true,
  'create-email-3': true,
  'create-embedded-form-from-type-page': true,
  'create-embedded-form-from-type-page-starter': true,
  'create-embedded-form-quickly': true,
  'create-landing-page-1': true,
  'create-landing-page-2': true,
  'edit-deal-stages': true,
  'edit-deal-stages-extended': true,
  'email-framework': true,
  'explore-email-template-1': true,
  'explore-email-template-2': true,
  'sms-edit': true,
  'sms-settings': true,
  'sms-sending': true,
  'filter-contacts-with-property-search': true,
  'go-to-getting-started': true,
  'import-tour': true,
  'new-contact-tour': true,
  profile: true,
  'publish-and-edit-content': true,
  'publish-and-edit-editor': true,
  'quick-import': true,
  test: true,
  'academy-tour': true,
  'new-contact-record': true,
  'imported-contacts-post-signup': true,
  'create-email-list-with-filter': true,
  'skip-data-sync-setup': true,
  'data-sync-setup-not-complete': true,
  'data-sync-setup-complete': true,
  'data-sync-flow': true,
  'data-sync-contacts': true,
  'download-email-extension': true,
  'listing-lib': true,
  'listing-lib-folders': true,
  'select-sales-email-template': true,
  'set-up-deals-pipeline': true,
  'sales-reporting-dashboards': true,
  'marketing-reporting-dashboards': true,
  'create-sales-reporting-dashboard': true,
  'create-marketing-reporting-dashboard': true,
  'rename-deal-pipeline': true,
  'sales-reporting': true,
  'post-import-contacts': true,
  'post-contact-wizard': true,
  'skip-setup-contacts': true,
  'payments-activation-professional-services-create': true,
  'payments-activation-professional-services-use': true,
  'payments-activation-subscriptions-create': true,
  'payments-activation-subscriptions-use': true,
  'payments-activation-paid-content-create': true,
  'payments-activation-paid-content-use': true,
  'payments-activation-projects-create': true,
  'payments-activation-projects-use': true,
  'payments-activation-physical-goods-create': true,
  'payments-activation-physical-goods-use': true,
  'payments-activation-donations-create': true,
  'payments-activation-donations-use': true,
  'comment-onboarding': true,
  'post-find-company-wizard': true,
  'post-setup-deals-pipeline-growth-plan': true,
  'post-setup-deals-pipeline-growth-plan-with-welcome': true,
  'post-setup-tickets-pipeline-growth-plan': true,
  'post-setup-tickets-pipeline-growth-plan-with-welcome': true,
  'post-setup-contacts-growth-plan': true,
  'post-setup-contacts-growth-plan-with-welcome': true,
  'post-setup-deals-pipeline-in-app-side-panel': true,
  'post-setup-deals-pipeline-in-app-side-panel-with-welcome': true,
  'post-setup-tickets-pipeline-in-app-side-panel': true,
  'post-setup-tickets-pipeline-in-app-side-panel-with-welcome': true,
  'post-setup-contacts-in-app-side-panel': true,
  'post-setup-contacts-in-app-side-panel-with-welcome': true,
  'in-app-side-panel-deal-stages': true,
  'in-app-side-panel-ticket-stages': true,
  'in-app-side-panel-views': true,
  'in-app-side-panel-properties': true,
  'in-app-side-panel-lists': true,
  'in-app-side-panel-reports-dashboard': true,
  'template-empty-state': true,
  'create-template': true,
  postWizardDashboard: true
};
export default validTours;